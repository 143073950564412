<template>
  <div class="search">
    <div v-if="status==='NOT-LOGGED-IN' || status==='LOGIN-ERROR' || status==='TOKEN-EXPIRED'" class="card">
      <div class="header">Login</div>
      <form class="content login" @submit.prevent="saveAuth">
        <div class="required">
          <label for="userName">User-Name:</label>
          <input id="userName" v-model="userName" tabindex="1"/>
        </div>
        <div class="required">
          <label for="password">Password:</label>
          <input id="password" v-model="auth.password" tabindex="2" type="password"/>
        </div>
        <div>
          <button type="submit">Anmelden</button>
        </div>
        <div v-if="status==='LOGIN-ERROR'">
          <span class="error-message">Die eingegebenen Login-Daten sind existieren nicht.</span>
        </div>
        <div v-if="status==='TOKEN-EXPIRED'">
          <span class="error-message">Ihre Session ist abgelaufen. Bitte loggen Sie sich erneut ein.</span>
        </div>
      </form>
    </div>
    <div v-if="status!=='LOGIN-ERROR' && status!=='NOT-LOGGED-IN' && status!=='TOKEN-EXPIRED'" class="card">
      <div class="header">Suchparameter</div>
      <form class="content" @submit.prevent="doSearch">
        <div>
          <label for="title">Titel:</label>
          <input id="title" v-model="search.person.title.academic" tabindex="1"/>
        </div>
        <div class="required">
          <label for="postcode">PLZ:</label>
          <input id="postcode" v-model="search.address.postCode" tabindex="5"/>
        </div>
        <div class="required">
          <label for="firstname">Vorname:</label>
          <input id="firstname" v-model="search.person.name.first" tabindex="2"/>
        </div>
        <div class="required">
          <label for="city">Stadt:</label>
          <input id="city" v-model="search.address.city" tabindex="6"/>
        </div>
        <div class="required">
          <label for="lastname">Nachname:</label>
          <input id="lastname" v-model="search.person.name.last" tabindex="3"/>
        </div>
        <div class="required">
          <label for="street">Straße:</label>
          <input id="street" v-model="search.address.street" tabindex="7"/>
        </div>
        <div>
          <label for="dob">Geburtsdatum:</label>
          <input id="dob" v-model="search.person.date.birth" tabindex="4"/>
        </div>
        <div>
          <label for="housenumber">Hausnummer:</label>
          <input id="housenumber" v-model="search.address.houseNumber" tabindex="8"/>
        </div>
        <div>
          <button>Suchen</button>
        </div>
      </form>
    </div>
    <div v-if="status==='SEARCHING'">Suche nach Identitäten...</div>
    <div v-if="status==='ERROR'">Es ist ein Fehler aufgetreten. Bitte versuche es erneut.</div>
    <div v-if="status==='SEARCHED'">
      <div v-for="(identity, k) in identities" :key="identity.id" class="card">
        <div class="header">{{ k + 1 }}. Identität</div>
        <identity :data="identity" :rawData="rawData[k]" class="content"
                  @triggerIDSearch="doSearchIds($event)"></identity>
      </div>
    </div>
  </div>
</template>

<script>
import api from "../../api"
import Identity from "../widgets/Identity"
import {mutationTypes} from "@/store/mutations"

import {v4 as uuidv4} from 'uuid';
import store from "@/store";

export default {
  name: "search",
  props: [],
  components: {
    Identity
  },
  data() {
    return {
      status: "NOT-LOGGED-IN",
      userName: "",
      auth: {
        password: "",
        idToken: "",
      },
      search: {
        person: {
          title: {
            academic: ""
          },
          name: {
            first: "",
            last: ""
          },
          date: {
            birth: ""
          }
        },
        address: {
          postCode: "",
          city: "",
          street: "",
          houseNumber: ""
        },
      },
      identities: [],
      rawData: []
    }
  },
  watch: {
    search: {
      handler: function () {
        this.status = "NOT-SEARCHED"
      },
      deep: true
    }
  },
  methods: {
    saveAuth() {
      if (this.userName && this.auth.password) {
        api.getToken(this.userName, this.auth.password).then((response) => {
          this.$store.commit(mutationTypes.USER_NAME_SAVE, this.userName)
          this.$store.commit(mutationTypes.CREDENTIALS_SAVE, response.data.id_token)
          this.$store.commit(mutationTypes.TOKEN_EXPIRATION_TIME_SAVE, this.getJwtExpiration(response.data.id_token))
          this.status = "NOT-SEARCHED"
        }).catch(() => {
          this.status = "LOGIN-ERROR"
        })
      }
    },
    getJwtExpiration(token) {
      const base64Url = token.split('.')[1];
      const decodedValue = JSON.parse(window.atob(base64Url));

      return decodedValue.exp;
    },
    isTokenExpired() {
      return Math.floor(new Date().getTime() / 1000) >= this.$store.state.credentials.exp;
    },
    tokenExpired() {
      store.commit(mutationTypes.CREDENTIALS_DELETE);
      this.status = "TOKEN-EXPIRED";
    },
    doSearch() {

      if (this.isTokenExpired()) {
        this.tokenExpired();
        return;
      }

      this.status = "SEARCHING"
      this.identities = [];
      let payload = {
        reference: uuidv4(),
        search: {
          person: {
            title: {
              academic: this.search.person.title.academic || null
            },
            name: {
              first: this.search.person.name.first || null,
              last: this.search.person.name.last || null
            },
            date: {
              birth: this.search.person.date.birth || null
            }
          },
          address: {
            postCode: this.search.address.postCode || null,
            city: this.search.address.city || null,
            street: this.search.address.street || null,
            houseNumber: this.search.address.houseNumber || null
          }
        },
        config: {
          enrichEdges: true
        }
      }

      function cloneObjectArray(identities) {
        return identities.map(identity => Object.assign({}, identity));
      }

      api.ir
          .search(payload)
          .then((identities) => {
            let clonedIdentities = cloneObjectArray(identities);
            clonedIdentities.forEach(entry => {
              const allIds = [];
              const keys = Object.keys(entry.duplicates);

              const map = keys.map(key => {
                const ids = entry.duplicates[key];
                allIds.push(...ids);

                return {
                  ...entry.dataSets.find(dataSet => dataSet.id === key),
                  ids: ids
                }
              });
              map.push(...this.findMissingEntry(allIds, entry));
              entry.dataSets = map;
            });
            this.identities = clonedIdentities;
            this.rawData = identities;
            this.status = "SEARCHED";
          })
          .catch(() => {
            this.status = "ERROR"
          })
    },
    findMissingEntry(ids, identities) {
      if (ids.length === identities.dataSets.length) {
        return [];
      }
      const dataSetIds = identities.dataSets.map(dataSet => dataSet.id);

      return dataSetIds.filter(id => !ids.includes(id))
          .map((id) => identities.dataSets.find(dataSet => dataSet.id === id));
    },
    doSearchIds(ids) {
      this.status = "SEARCHING"
      this.identities = [];
      let payload = {
        reference: uuidv4(),
        search: {
          ids: ids
        },
        config: {
          enrichEdges: true
        }
      }
      api.ir
          .searchids(payload)
          .then((identities) => {
            this.identities = identities
            this.status = "SEARCHED"
          })
          .catch(function () {
            this.status = "ERROR"
          })
    }
  },
  created() {
    this.userName = process.env.VUE_APP_USERNAME
    this.auth.password = process.env.VUE_APP_PASSWORD

    if (this.userName && this.auth.password) {
      this.saveAuth()
    }
  }
}
</script>

<style lang="scss">
.search form {
  display: grid;
  grid-template-columns: 330px 330px;

  div {
    padding: 5px 0;
  }

  label {
    width: 110px;
    display: inline-block;
  }

  .required label::after {
    content: " *";
    color: red;
  }

  input {
    width: 170px;
  }
}

.search form.login {
  grid-template-columns: 330px;
}

.error-message {
  color: red;
}
</style>
