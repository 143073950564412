<template>
  <div class="identity">
    <div class="tabs" v-if="!identityChanged">
      <ul class="nav">
        <li v-bind:class="{active: activeTab === 'table'}" @click="activeTab='table'">Tabelle</li>
        <li v-bind:class="{active: activeTab === 'graph'}" @click="activeTab='graph'">Graph</li>
        <li v-bind:class="{active: activeTab === 'vtmap'}" @click="activeTab='vtmap'">VT-Karte</li>
        <li v-bind:class="{active: activeTab === 'conban'}" @click="activeTab='conban'">Verbindungsverbote</li>
        <li v-bind:class="{active: activeTab === 'assoc'}" @click="activeTab='assoc'">Verbund</li>
        <li v-bind:class="{active: activeTab === 'raw'}" @click="activeTab='raw'">Rohdaten</li>
      </ul>
      <div class="data">
        <identity-table v-if="activeTab === 'table'" :data="data"></identity-table>
        <identity-graph-container v-bind:style="{display: activeTab === 'graph' ? 'block' : 'none'}" :graph="identityGraph" @identityChanged="identityChanged=$event"></identity-graph-container>
        <identity-map-container v-bind:style="{display: activeTab === 'vtmap' ? 'block' : 'none'}" :graph="identityGraph"></identity-map-container>
        <connection-bans v-bind:style="{display: activeTab === 'conban' ? 'block' : 'none'}" :data="data" @clickedOther="$emit('triggerIDSearch', [$event])" @identityChanged="identityChanged=$event"></connection-bans>
        <associations v-if="data.associations" v-bind:style="{display: activeTab === 'assoc' ? 'block' : 'none'}" :data="data" @clickedOther="$emit('triggerIDSearch', [$event])" @identityChanged="identityChanged=$event"></associations>
        <div v-if="activeTab === 'raw'">
          <button @click="copyToClipboard()" class="btnCopyRawData" :disabled="!rawData">In Zwischenablage kopieren</button>
          <pre><code id=rawData>{{rawData}}</code></pre>
          <div id="snackbar">{{snackbarText}}</div>
        </div>
      </div>
    </div>
    <div class="changed" v-if="identityChanged">
      <p>Die Identität wurde erfolgreich bearbeitet:</p>
      <ul>
        <li>{{ identityChanged.deletedEdges }} Verknüpfungen wurden gelöscht</li>
        <li>{{ identityChanged.deletedDataSets }} Datensätze wurden gelöscht</li>
        <li>{{ identityChanged.identityIDs.length }} Identitäten sind nun vorhanden</li>
      </ul>
      <div v-if="identityChanged.identityIDs.length &gt; 0">
        <button @click="$emit('triggerIDSearch', identityChanged.identityIDs)">Neue Identitäten anzeigen</button>
      </div>
    </div>
  </div>
</template>

<script>
import IdentityTable from "./IdentityTable"
import IdentityMapContainer from "./IdentityMapContainer"
import IdentityGraphContainer from "./IdentityGraphContainer"
import ConnectionBans from "./ConnectionBans"
import Associations from "./Associations"

function syntaxHighlight(json) {
  if (!json) return ""; //no JSON from response

  json = json
      .replace(/&/g, "&amp;")
      .replace(/</g, "&lt;")
      .replace(/>/g, "&gt;");
  return json.replace(
      /("(\\u[a-zA-Z0-9]{4}|\\[^u]|[^\\"])*"(\s*:)?|\b(true|false|null)\b|-?\d+(?:\.\d*)?(?:[eE][+-]?\d+)?)/g,
      function (match) {
        var cls = "number";
        if (/^"/.test(match)) {
          if (/:$/.test(match)) {
            cls = "key";
          } else {
            cls = "string";
          }
        } else if (/true|false/.test(match)) {
          cls = "boolean";
        } else if (/null/.test(match)) {
          cls = "null";
        }
        return '<span class="' + cls + '">' + match + "</span>";
      }
  );
}

export default {
  name: "identity",
  components: {
    IdentityTable,
    IdentityMapContainer,
    IdentityGraphContainer,
    ConnectionBans,
    Associations
  },
  props: ["data", "rawData"],
  data() {
    return {
      activeTab: "table",
      identityChanged: null,
      snackbarText: ""
    }
  },
  computed: {
    identityGraph() {
      let graph = {}
      const graphArray = new Map();

      this.data.dataSets.forEach(dataSet => {
        const ids = [];
        if(dataSet.ids){
          ids.push(...dataSet.ids);
        }else{
          ids.push(dataSet.id);
        }

        graph[dataSet.id] = {
          id: dataSet.id,
          attributes: {
            "person.title.academic": dataSet.person.title?.academic,
            "person.name.first": dataSet.person.name.first,
            "person.name.last": dataSet.person.name.last,
            "person.date.birth": dataSet.person.date?.birth,
            "person.gender": dataSet.person.gender,
            "address.postCode": dataSet.address.postCode,
            "address.city": dataSet.address.city,
            "address.street": dataSet.address.street,
            "address.houseNumber": dataSet.address.houseNumber,
            "address.houseNumberSupplement":
            dataSet.address.houseNumberSupplement,
            "knownEmails": dataSet.knownEmails?.[0],
            "knownTelephones": dataSet.knownTelephones?.[0]
          },
          incoming: [],
          outgoing: [],
          ids: ids
        }

        if(dataSet.ids) {
          dataSet.ids.forEach(id => {
            graphArray.set(id, dataSet.id);
          })
        }else{
          graphArray.set(dataSet.id, dataSet.id);
        }
      });

      this.data.edges.forEach(edges => {
        let edge = edges.split(":")
        edge[0] = graphArray.get(edge[0]);
        edge[1] = graphArray.get(edge[1]);
        graph[edge[0]].outgoing.push({id: edge[1], rule: edge[2]})
        graph[edge[1]].incoming.push({id: edge[0], rule: edge[2]})
      })

      return graph
    }
  },
  updated() {
    if (this.activeTab === 'raw') {
      var rawDataElement = document.getElementById('rawData');
      if (rawDataElement) {
        rawDataElement.innerHTML = syntaxHighlight(JSON.stringify(this.rawData, null, 3));
      }
    }
  },
  methods: {
    copyToClipboard() {
      const snackbar = document.getElementById("snackbar");

      if(this.rawData){
        navigator.clipboard.writeText(JSON.stringify(this.rawData, undefined, 2));
        this.snackbarText = "Rohdaten in die Zwischenablage kopiert"
      }else {
        this.snackbarText = "keine Rohdaten vorhanden"
      }

      snackbar.className = "show";

      setTimeout(function(){ snackbar.className = snackbar.className.replace("show", ""); }, 3000);
    }
  }
}
</script>

<style lang="scss">
.identity {
  .tabs {
    .nav {
      li {
        display: inline-block;
        border-bottom: 2px solid #fff;
        cursor: pointer;
        margin-right: 10px;
      }

      .active {
        border-bottom: 2px solid #20a8d8;
      }
    }

    .data {
      padding: 10px 0 0;
    }
  }

  textarea {
    width: 99%;
    height: 200px;
  }

  .changed {
    p {
      margin: 0;
      color: green;
    }

    ul {
      margin: 10px 0 0 20px;
    }

    button {
      margin: 5px 0;
    }
  }
}

pre {
  background-color: ghostwhite;
  border: 1px solid silver;
  padding: 10px 20px;
  margin: 20px;
  border-radius: 4px;
  height: 60vh;
  margin-left: auto;
  margin-right: auto;
  overflow: scroll;
}

.string {
  color: #545726;
}
.number {
  color: darkorange;
}
.boolean {
  color: blue;
}
.null {
  color: magenta;
}
.key {
  color: darkred;
  font-weight: bold;
}

.btnCopyRawData {
  margin-top: 10px;
}

#snackbar {
  visibility: hidden;
  min-width: 250px;
  margin-left: -125px;
  background-color: #333;
  color: #fff;
  text-align: center;
  border-radius: 2px;
  padding: 16px;
  position: fixed;
  z-index: 1;
  left: 50%;
  bottom: 30px;
}

#snackbar.show {
  visibility: visible;
  -webkit-animation: fadein 0.5s, fadeout 0.5s 2.5s;
  animation: fadein 0.5s, fadeout 0.5s 2.5s;
}
</style>
