<template>
  <span class="identity-preview">
    <i v-if="status==='SEARCHING'">(lädt noch)</i>
    <i class="error" v-if="status==='ERROR'">(Fehler bei Identitätssuche)</i>
    <a v-if="status==='SEARCHED'" href="#" @click.prevent="$emit('click', id)">
      {{ example.person.name.first }} {{ example.person.name.last }}, {{ example.address.city }}
    </a>
    <i class="hint" v-if="status==='SEARCHED'">({{ dataSetsLength }} Datensätze)</i>
  </span>
</template>

<script>
import api from "../../api"

import { v4 as uuidv4 } from 'uuid';

export default {
  name: "identity-preview",
  props: ["id"],
  data() {
    return {
      identity: null,
      status: ""
    }
  },
  computed: {
    example() {
      return this.identity.dataSets[0]
    },
    dataSetsLength() {
      return this.identity.dataSets.length
    }
  },
  created() {
    this.status = "SEARCHING"
    const payload = {
      reference: uuidv4(),
      search: {
        ids: [this.id]
      }
    }
    api.ir
      .searchids(payload)
      .then((identities) => {
        this.identity = identities[0]
        this.status = "SEARCHED"
      })
      .catch(() => {
        this.status = "ERROR"
      })
  }
}
</script>

<style lang="scss">
.identity-preview {
  i {
    color: #ccc;
  }
  .error {
    color: #f00;
  }
  .hint {
    margin-left: 5px;
  }
  a {
    color: #20a8d8;
    text-decoration: none;
  }
}
</style>
