import { mutations } from './mutations'
import Vuex from "vuex";


export default Vuex.createStore({
  state: {
    credentials: {
      idToken: "",
      exp:""
    },
    user: {
      name: ""
    }
  },
  mutations
})