<template>
  <div class="data-set-info">
    <h4>{{ labelFor(id) }}</h4>
    <table>
      <tr v-for="(v, k) in graph[id].attributes" v-bind:key="v">
        <td>{{ attributeLabels[k] }}:</td>
        <td>{{ v }}</td>
      </tr>
    </table>
    <div class="edge" v-for="v in graph[id].incoming.concat(graph[id].outgoing)" v-bind:key="v"
         @mouseover="$emit('highlight', v.id)" @mouseout="$emit('highlight', null)" @click.stop="$emit('click', v.id)">
      <span>{{ v.rule }}: {{ labelFor(v.id) }}</span>
    </div>
  </div>
</template>

<script>
import Attributes from '../../attributes'

export default {
  name: "data-set-info",
  props: ["graph", "id", "labels"],
  data() {
    return {
      attributeLabels: Attributes
    }
  },
  methods: {
    labelFor(id) {
      const attributes = this.graph[id].attributes
      return this.valueFor(attributes, this.labels)
    },
    valueFor(attributes, names) {
      if (!names) {
        return ""
      }
      let value = []
      for (let i in names) {
        value[i] = attributes[names[i]]
      }
      return value.join(", ")
    }
  }
}
</script>

<style lang="scss">
.data-set-info {
  h4 {
    margin: 0 0 10px;
  }

  table {
    border-spacing: 0;
  }
  td {
    padding: 0 5px 0 0;
  }

  .edge:hover{
    cursor: pointer;
    background: #f0f3f5;
  }
}
</style>
