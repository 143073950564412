<template>
  <div id="sidebar">
    <nav>
      <ul>
        <li>
          <router-link :to="{name: 'Search'}">Suche</router-link>
        </li>
      </ul>
    </nav>
  </div>
</template>

<script>
export default {
  name: "sidebar",
  props: [],
  data() {
    return {}
  }
}
</script>