import Container from '../components/fragments/Container'
import Search from '../components/pages/Search'
import {createRouter, createWebHistory} from "vue-router";


const routes = [
  {
    path: "/",
    component: Container,
    meta: {
      label: "Start"
    },
    children: [
      {
        path: "",
        component: Search,
        name: "Search"
      }
    ]
  }
]

const router = createRouter({
      history: createWebHistory(),
      routes
    }
);


export default router