<template>
  <g>
    <polygon :points="pointsRendered" @click.stop="$emit('click')"></polygon>
    <text v-if="showText" :x="label.x" :y="label.y">{{ label.text }}</text>
  </g>
</template>

<script>
export default {
  name: "poly",
  props: ["points", "label", "depth", "options"],
  data() {
    return {}
  },
  computed: {
    pointsRendered() {
      return this.points.map(e => e[0] + "," + e[1]).join(" ")
    },
    showText() {
      return this.label && (
          (this.depth === 2 && this.options.showLabel) ||
          (this.depth === 1 && this.options.showGroup)
      )
    }
  }
}
</script>