<template>
  <div class="data-set-edit-list">
    <h4>Datensätze</h4>
    <div v-for="dataSet in graph" :key="dataSet.id" :class="{active: options.selectedNodes.includes(dataSet.id)}" @mouseover="$emit('highlight', dataSet.id)" @mouseout="$emit('highlight', null)" @click.stop="$emit('click', {type: 'node', id: dataSet.id})">{{ labelFor(dataSet.id) }}</div>
    <h4>Verknüpfungen</h4>
    <div v-for="edge in edgeList" :key="edge.a + ':' + edge.b" :class="{active: isActiveLink(edge.a, edge.b)}" @mouseover="$emit('highlightEdge', edge)" @mouseout="$emit('highlightEdge', null)" @click.stop="$emit('click', {type: 'edge', edge: edge})">{{ labelFor(edge.a) }} zu {{ labelFor(edge.b) }}</div>
  </div>
</template>

<script>
export default {
  name: "date-set-edit-list",
  props: ["graph", "labels", "options"],
  methods: {
    labelFor(id) {
      const attributes = this.graph[id].attributes
      return this.valueFor(attributes, this.labels)
    },
    valueFor(attributes, names) {
      if (!names) {
        return ""
      }
      let value = []
      for (let i in names) {
        value[i] = attributes[names[i]]
      }
      return value.join(", ")
    },
    isActiveLink(a, b) {
      if (
          this.options.selectedNodes.includes(a) ||
          this.options.selectedNodes.includes(b)
      ) {
        return true
      }
      for (let i = 0; i < this.options.selectedEdges.length; i++) {
        let other = this.options.selectedEdges[i]
        if (a === other.a && b === other.b) {
          return true
        }
      }
      return false
    }
  },
  computed: {
    edgeList() {
      let m = {}
      for (let id in this.graph) {
        let dataSet = this.graph[id]
        for (let i = 0; i < dataSet.outgoing.length; i++) {
          let out = dataSet.outgoing[i]
          if (out.rule === "DUP") {
            continue
          }
          let a = out.id < id ? out.id : id
          let b = out.id < id ? id : out.id
          if (typeof m[a] == "undefined") {
            m[a] = {}
          }
          m[a][b] = true
        }
      }
      let list = []
      for (let a in m) {
        for (let b in m[a]) {
          list[list.length] = { a: a, b: b }
        }
      }
      return list
    }
  }
}
</script>

<style lang="scss">
.data-set-edit-list {
  h4 {
    margin: 20px 0 10px;
  }
  h4:first-child {
    margin-top: 0;
  }
  .active,
  .active:hover {
    background: #f59c00;
    color: #fff;
  }
  div:hover {
    cursor: pointer;
    background: #f0f3f5;
  }
}
</style>
