<template>
  <table class="identity-table">
    <thead>
      <tr>
        <th>Anzahl</th>
        <th>Titel</th>
        <th>Vorname</th>
        <th>Nachname</th>
        <th>Geburtsdatum</th>
        <th>Geschlecht</th>
        <th>PLZ</th>
        <th>Stadt</th>
        <th>Straße</th>
        <th>Hausnummer</th>
        <th>Hausnummerzusatz</th>
        <th>Addresszusatz</th>
        <th>Telefonnummer</th>
        <th>E-Mail</th>
      </tr>
    </thead>
    <tr v-for="(dataSet, id) in data.dataSets" :key="id">
      <td> <div class="circle">{{ dataSet.ids ? dataSet.ids.length : 1 }}</div></td>
      <td>{{ dataSet.person.title ? (dataSet.person.title.academic || null): null }}</td>
      <td>{{ dataSet.person.name.first }}</td>
      <td>{{ dataSet.person.name.last }}</td>
      <td>{{ dataSet.person.date ? (dataSet.person.date.birth || null): null }}</td>
      <td>{{ dataSet.person.gender ? (translateGender(dataSet.person.gender)) : null}}</td>
      <td>{{ dataSet.address.postCode }}</td>
      <td>{{ dataSet.address.city }}</td>
      <td>{{ dataSet.address.street }}</td>
      <td>{{ dataSet.address.houseNumber }}</td>
      <td>{{ dataSet.address.houseNumberSupplement }}</td>
      <td>{{ dataSet.address.addressSupplement }}</td>
      <td>{{ dataSet.knownTelephones ? dataSet.knownTelephones[0] : null }}</td>
      <td>{{ dataSet.knownEmails ? dataSet.knownEmails[0] : null}}</td>
    </tr>
  </table>
</template>

<script>
export default {
  name: "identity-table",
  props: ["data"],
  data() {
    return {}
  },
  methods: {
    translateGender(gender){
      if(gender === 'MALE'){
        return 'Männlich'
      }else if(gender === 'FEMALE'){
        return 'Weiblich'
      }else {
        return ''
      }
    }
  }
}
</script>

<style lang="scss">
.identity-table {
  th, td {
    padding: 0 5px;
    text-align: left;
  }
  th:first-child, td:first-child {
    padding-left: 0;
  }
}

.circle {
  border-radius: 50%;
  width: 40px;
  background: #E4E5E6;
  text-align: center;
}
</style>
