<template>
  <div>
    <div class="identity-map-container">
      <div class="left">
        <identity-map :graph="graph" :groupBy="groupBy" :label="label" height="500" width="686" :options="options" @click="options.activeID=$event"></identity-map>
        <div class="attribs">
          <draggable class="groupBy" v-model="groupBy" item-key="attribute" group="attributes">
            <template #item="attribute">
              <div>{{ attributeLabels[attribute.element] }}</div>
            </template>
          </draggable>
          <draggable class="groupBy" v-model="label" item-key="attribute" group="attributes">
            <template #item="attribute">
              <div>{{ attributeLabels[attribute.element] }}</div>
            </template>
          </draggable>
          <draggable class="groupBy" v-model="attributes" item-key="attribute" group="attributes">
            <template #item="attribute">
              <div>{{ attributeLabels[attribute.element] }}</div>
            </template>
          </draggable>
        </div>
        <div>
          <input id="vtmap-show-group" type="checkbox" v-model="options.showGroup"/>
          <label for="vtmap-show-group">Gruppierung anzeigen</label>
        </div>
        <div>
          <input id="vtmap-show-label" type="checkbox" v-model="options.showLabel"/>
          <label for="vtmap-show-label">Label anzeigen</label>
        </div>
      </div>
      <div class="right" v-if="options.activeID">
        <data-set-info :graph="graph" :id="options.activeID" :labels="groupBy.concat(label)" @highlight="options.highlightID=$event" @click="options.activeID=$event"></data-set-info>
      </div>
    </div>
  </div>
</template>

<script>
import Draggable from 'vuedraggable'
import IdentityMap from './IdentityMap'
import DataSetInfo from './DataSetInfo'
import Attributes from '../../attributes'

export default {
  name: "identity-map-container",
  components: {
    Draggable,
    IdentityMap,
    DataSetInfo
  },
  props: ["graph"],
  data() {
    return {
      groupBy: ["person.name.last"],
      label: ["person.name.first"],
      attributes: [
        "person.title.academic",
        "person.date.birth",
        "person.gender",
        "address.postCode",
        "address.city",
        "address.street",
        "address.houseNumber",
        "address.houseNumberSupplement",
        "knownEmails",
        "knownTelephones"
      ],
      options: {
        showGroup: true,
        showLabel: true,
        activeID: null,
        highlightID: null
      },
      attributeLabels: Attributes
    }
  }
}
</script>

<style lang="scss">
.identity-map-container {
  display: flex;

  .left {
    width: 686px;
    margin-right: 10px;
  }

  .attribs {
    display: flex;
  }
  .attribs>div {
    width: 200px;
    padding: 10px;
    margin: 10px 10px 10px 0;
    border: solid 1px #c8ced3;
  }
  .attribs>div:last-child {
    margin-right: 0;
  }
  .attribs>div div {
    cursor: move;
  }
  .attribs>div div:hover {
    background: #f0f3f5;
  }
}
</style>
