<template>
  <svg class="identity-map" :height="height" :width="width">
    <poly v-for="(v, k) in polygons" :key="k" :points="v.points" :label="v.label"
          :class="{p0: v.depth === 0, p1: v.depth === 1, p2: v.depth === 2,
          highlight: v.id === options.highlightID}" :depth="v.depth"
          :options="options" @click="showConnections(v.id)"></poly>
    <line v-for="v in activeConnections" :key="'connection-' + v.id" :x1="v.x1" :y1="v.y1" :x2="v.x2" :y2="v.y2"
          :style="'stroke-width:' + v.width*2"></line>
  </svg>
</template>

<script>
import * as d3 from 'd3'
import * as VT from 'd3-voronoi-treemap'
import Poly from './Poly'

export default {
  name: "identity-map",
  props: ["graph", "groupBy", "label", "height", "width", "options"],
  components: {
    Poly
  },
  data() {
    return {}
  },
  methods: {
    showConnections(id) {
      // eslint-disable-next-line vue/no-mutating-props
      this.options.activeID = id === this.options.activeID ? null : id
      if(this.options.activeID != null){
        this.$emit("click", this.options.activeID)
      }
    },
    valueFor(attributes, names) {
      if (!names) {
        return ""
      }
      let value = []
      for (let i in names) {
        value[i] = attributes[names[i]]
      }
      return value.join(", ")
    }
  },
  computed: {
    voronoiTreemap() {
      const grouped = d3.group(Object.values(this.graph), d => this.valueFor(d.attributes, this.groupBy));
      let nested = [];
      grouped.forEach((value, i) =>{
        nested.push({key: i, values: value })
      })
      const packable = {
        id: "root",
        values: nested
      }

      let hierarchy = d3
          .hierarchy(packable, d => d.values)
          .sum(d => d.incoming ? d.incoming.length + d.outgoing.length + 1 : 1)

      VT.voronoiTreemap().size([this.width, this.height])(hierarchy)

      return hierarchy
    },
    polygons() {
      let polygons = []
      this.voronoiTreemap.eachAfter((element) => {

        let labelText = element.data.attributes ? this.valueFor(element.data.attributes, this.label) : element.data.key
        polygons[polygons.length] = {
          id: element.data.id,
          points: element.polygon,
          depth: element.depth,
          label: labelText ? {
            x: element.polygon.site.x,
            y: element.polygon.site.y,
            text: labelText,
          } : null
        }
      })
      return polygons
    },
    coords() {
      let coords = {}
      this.voronoiTreemap.each((e) => {
        if (e.data.attributes) {
          coords[e.data.id] = {
            x: e.polygon.site.x,
            y: e.polygon.site.y,
          }
        }
      })
      return coords
    },
    activeConnections() {
      let connections = []
      if (!this.options.activeID) return connections
      const targets = {}
      const cons = this.graph[this.options.activeID].outgoing.concat(this.graph[this.options.activeID].incoming)
      for (let i in cons) {
        let id = cons[i].id
        if (!targets[id]) {
          targets[id] = 0
        }
        targets[id]++
      }
      const start = this.coords[this.options.activeID]
      for (let i in targets) {
        connections[connections.length] = {
          id: i,
          x1: start.x,
          y1: start.y,
          x2: this.coords[i].x,
          y2: this.coords[i].y,
          width: targets[i],
        }
      }
      return connections
    }
  }
}
</script>

<style lang="scss">
.identity-map {
  .p0 *,
  .p1 *,
  .p2 text {
    pointer-events: none;
  }

  .p1 text,
  .p2 text {
    text-anchor: middle;
    dominant-baseline: middle;
  }

  .p0 polygon {
    fill: none;
  }

  .p1 polygon {
    fill: none;
    stroke: #000;
    stroke-width: 5;
  }

  .p1 text {
    fill: rgba(255, 255, 255, 0.3);
    font-weight: bold;
    font-size: 150%;
  }

  .p2 polygon {
    fill: #20a8d8;
    stroke: #20a8d8;
    stroke-width: 1;
  }

  .p2 text {
    fill: #fff;
  }

  .p2 polygon:hover, .p2.highlight polygon {
    fill: #63c2de;
    stroke: #63c2de;
  }

  line {
    stroke: rgba(255, 255, 255, 0.3);
    pointer-events: none;
  }
}
</style>
