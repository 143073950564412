export const mutationTypes = {
  CREDENTIALS_SAVE: 'credentials/save',
  USER_NAME_SAVE: 'user/name/save',
  CREDENTIALS_DELETE: 'credentials/delete',
  TOKEN_EXPIRATION_TIME_SAVE: 'credentials/expiration'
}

export const mutations = {
  [mutationTypes.CREDENTIALS_SAVE]: function (state, idToken) {
    state.credentials.idToken = idToken
  },
  [mutationTypes.USER_NAME_SAVE]: function (state, userName) {
    state.user.name = userName
  },
  [mutationTypes.TOKEN_EXPIRATION_TIME_SAVE]: function(state, expirationTime) {
    state.credentials.exp = expirationTime
  },
  [mutationTypes.CREDENTIALS_DELETE]: function(state) {
    state.user.name = '';
    state.credentials.idToken = '';
    state.credentials.exp = '';
  }
}