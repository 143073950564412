const labels = {
  "person.title.academic": "Titel",
  "person.name.first": "Vorname",
  "person.name.last": "Nachname",
  "person.date.birth": "Geburtsdatum",
  "person.gender": "Geschlecht",
  "address.postCode": "PLZ",
  "address.city": "Stadt",
  "address.street": "Straße",
  "address.houseNumber": "Hausnummer",
  "address.houseNumberSupplement": "Hausnummerzusatz",
  "knownEmails": "E-Mail-Adresse",
  "knownTelephones": "Telefonnummer"
}

export default labels