<template>
  <div id="rendered">
    <div id="header">
      <div id="logo">
        <img alt="Regis24" src="./logo.svg">
        <h1>Identity-Resolution DEMOs</h1>
      </div>
      <button v-if="isLoggedIn" id="btn-logout" v-on:click="logout">Logout</button>
    </div>
    <div id="wrapper">
      <Sidebar/>
      <div id="main">
        <router-view/>
      </div>
    </div>
  </div>
</template>


<script>

import Sidebar from "@/components/fragments/Sidebar.vue";
import store from "@/store";
import {mutationTypes} from "@/store/mutations";
import Search from "@/components/pages/Search.vue";

export default {
  components: {
    Sidebar
  },
  computed: {
    isLoggedIn() {
      return Boolean(store.state.credentials.idToken);
    }
  },
  methods: {
    logout() {
      store.commit(mutationTypes.CREDENTIALS_DELETE);
      Search.data().status = "NOT-LOGGED-IN";
      window.location.reload();
    },
  }
}
</script>
<style lang="scss">
html, body, #rendered, #app {
  min-height: 100%;
  height: 100%
}

body {
  margin: 0;
  height: 100%;
}

#rendered {
  font-family: 'Avenir', Helvetica, Arial, sans-serif;
  color: #2c3e50;
  font-size: 10pt;
  display: flex;
  flex-direction: column;

  #header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 0 13px;
    border-bottom: solid 1px #2f353a;

    #logo {
      display: inline-flex;

      h1 {
        font-size: 150%;
        margin-left: 20px;
      }

      img {
        width: 260px;
      }
    }
  }

  #wrapper {
    display: flex;
    flex: 1;
    flex-direction: row;


    #sidebar {
      width: 10%;
      background: #2f353a;
      padding-top: 10px;

      a {
        display: block;
        color: #fff;
        font-size: 100%;
        text-decoration: none;
        padding: 10px 0 10px 15px;
      }

      a:hover {
        background: #20a8d8;
      }
    }

    #main {
      flex: 1;
      width: 100%;
      padding: 10px 15px;
      background: #e4e5e6;
    }
  }

  .card {
    border: solid 1px #c8ced3;
    border-top: solid 2px #20a8d8;
    margin-bottom: 10px;

    .header {
      background: #f0f3f5;
      padding: 10px 0 10px 10px;
      border-bottom: solid 1px #c8ced3;
    }

    .content {
      background: #fff;
      padding: 10px;
    }
  }
}

ul {
  margin: 0;
  padding: 0;
}

li {
  list-style: none;
}
</style>
