import axios from 'axios'
import store from '../store'
import {toRaw} from "vue";

const identityFragment = `        
        fragment identityData on Identity {
          id
          edges
          duplicates
          connectionBans{
            reason
            user
            time
            other
          }
          dataSets {
            id
            person {
              name {
                first
                last
              }
              title {
                academic
              }
              date {
                birth
              }
              gender
            }
            knownEmails
            knownTelephones           
            address {
              city
              postCode
              street
              houseNumber
              houseNumberSupplement
              addressSupplement
            }
            meta {
              eventTime
              origin
              originID
            }
          }
        }`

const gqlURL = process.env.VUE_APP_GRAPHQL_URL

const api = {
  getToken: (username, password) => {
    let url = process.env.VUE_APP_AUTH_URL

    let request = {
      method: 'POST',
      url: url,
      headers: {
        'content-type': 'application/x-www-form-urlencoded'
      },
      auth: {
        username: username,
        password: password
      },
      data: {}
    }
    return axios(request)
  },
  ir: {
    search: (payload) => {
      const search = toRaw(payload.search);
      removeNulls(search)

      let gqlPayload = {
        query: `query search($person: Person!, $address: Address!) {
          normalize(person: $person, address: $address) {
            searchIdentities {
              ...identityData
            }
          }
        }` + identityFragment,
        variables: {
          person: search.person,
          address: search.address
        }
      }
      return sendPost(gqlPayload, gqlURL).then(response => {
        return response.normalize.searchIdentities
      })
    },
    searchids: (payload) => {
      removeNulls(payload)
      let gqlPayload = {
        query: `query {
        `
      }

      let i = 0
      payload.search.ids.forEach(id => {

        gqlPayload.query += `id` + i + `: identity(id: "` + id + `") {
          ...identityData
        }
        `
        i++
      })
      gqlPayload.query += `}` + identityFragment
      return sendPost(gqlPayload, gqlURL).then(response => {
        let identities = []
        for (let key in response){
          identities.push(response[key])
        }
        return identities
      })
    },
    disassemble: (payload) => {
      let gqlPayload = {
        query: `mutation disassemble($reference: ID!, $dataSetIDs: [ID!]!, $edges: [DisassembleEdge!]!, $createConnectionBan: Boolean!, $meta: DisassembleMeta!) {
          disassemble (
            reference: $reference
            dataSetIDs: $dataSetIDs
            edges: $edges
            createConnectionBan: $createConnectionBan
            meta: $meta
          ) {
            deletedDataSets,
            deletedEdges,
            identityIDs
          }
        }`,
        variables: {
          reference: payload.reference,
          edges: payload.edges,
          dataSetIDs: payload.dataSetIDs,
          meta: payload.meta,
          createConnectionBan: payload.createConnectionBan
        }
      }
      return sendPost(gqlPayload, gqlURL).then(response => {
        return response.disassemble
      })
    },
    removeConnectionBan: (payload) => {
      let gqlPayload = {
        query: `mutation removeConnectionBan($reference: ID!, $identityID: ID!, $others: [ID!]!, $meta: ConnectionBanMeta!) {
          removeConnectionBan(reference: $reference, identityID: $identityID, others: $others, meta: $meta)
        }`,
        variables: {
          reference: payload.reference,
          identityID: payload.identityID,
          others: payload.others,
          meta: payload.meta,
        }
      }
      return sendPost(gqlPayload, gqlURL)
    }
  }
}

const sendPost = (payload, url) => {
  let request = {
    method: "POST",
    url: url,
    data: payload,
    headers: {
      'content-type': 'application/json',
      'authorization': 'Bearer ' + store.state.credentials.idToken
    }
  }
  return axios(request).then(response => {
    if (response.data.errors) {
      throw response.data.errors
    }
    return response.data.data
  })
}

const removeNulls = obj => {
  Object.keys(obj).forEach(key => {
    if (obj[key] && typeof obj[key] == "object") {
      removeNulls(obj[key])
      if (Object.keys(obj[key]).length === 0) {
        delete obj[key]
      }
    } else if (obj[key] == null) {
      delete obj[key]
    }
  })
}

export default api
