<template>
  <div>
    <div class="identity-graph-container">
      <div class="left">
        <identity-graph :graph="graph" height="500" width="686" :options="options" :labels="labels"
                        @click="clickGraph"></identity-graph>
        <div class="attribs">
          <draggable class="groupBy" v-model="labels" item-key="attribute" group="attributes">
            <template #item="attribute">
              <div>{{ attributeLabels[attribute.element] }}</div>
            </template>
          </draggable>
          <draggable class="groupBy" v-model="attributes" item-key="attribute" group="attributes">
            <template #item="attribute">
              <div>{{ attributeLabels[attribute.element] }}</div>
            </template>
          </draggable>
        </div>
        <div>
          <input id="graph-show-label" type="checkbox" v-model="options.showLabel"/>
          <label for="graph-show-label">Label anzeigen</label>
        </div>
      </div>
      <div class="right">
        <data-set-info v-if="!edit &amp;&amp; options.selectedNodes.length === 1" :graph="graph"
                       :id="options.selectedNodes[0]" :labels="labels" @highlight="options.highlightID=$event"
                       @click="clickGraph({type: 'node', id: $event})"></data-set-info>
        <button @click="changeGraphMode" v-if="!edit">Identität bearbeiten</button>
        <data-set-edit-list v-if="edit" :graph="graph" :labels="labels" :options="options"
                            @highlight="options.highlightID=$event" @highlightEdge="options.highlightEdge = $event"
                            @click="clickGraph($event)"></data-set-edit-list>
        <div class="edit-meta" v-if="edit">
          <h4>Zusätzliche Angaben</h4>
          <div class="required">
            <label for="edit-reason">Änderungsgrund:</label>
          </div>
          <div>
            <input id="edit-reason" v-model="editReason" placeholder="Bitte einen Änderungsgrund angeben."/>
          </div>
          <div>
            <input id="edit-connection-ban" type="checkbox" v-model="editCreateConnectionBan"/>
            <label for="edit-connection-ban">Verbindungsverbot erstellen</label>
          </div>
        </div>
        <button @click="sendDisassemble" v-if="edit"
                :disabled="(options.selectedNodes.length === 0 &amp;&amp; options.selectedEdges.length === 0) || editReason === ''">
          Löschen
        </button>
        <button @click="changeGraphMode" v-if="edit">Abbrechen</button>
      </div>
    </div>
  </div>
</template>

<script>
import Draggable from 'vuedraggable';
import IdentityGraph from "./IdentityGraph"
import DataSetInfo from "./DataSetInfo"
import Attributes from "../../attributes"
import DataSetEditList from "./DataSetEditList"
import api from "../../api"
import store from "../../store"

import {v4 as uuidv4} from 'uuid';

export default {
  name: "identity-graph-container",
  components: {
    Draggable,
    IdentityGraph,
    DataSetInfo,
    DataSetEditList
  },
  props: ["graph"],
  data() {
    return {
      highlightID: null,
      labels: ["person.name.last", "person.name.first"],
      attributes: [
        "person.title.academic",
        "person.date.birth",
        "person.gender",
        "address.postCode",
        "address.city",
        "address.street",
        "address.houseNumber",
        "address.houseNumberSupplement",
        "knownEmails",
        "knownTelephones"
      ],
      options: {
        showLabel: true,
        selectedNodes: [],
        selectedEdges: [],
        class: "",
        highlightID: null,
        highlightEdge: null
      },
      attributeLabels: Attributes,
      edit: false,
      editReason: "",
      editCreateConnectionBan: false
    }
  },
  methods: {
    changeGraphMode() {
      this.edit = !this.edit
      if (this.edit) {
        this.options.class = "edit"
      } else {
        this.options.class = ""
      }
      this.options.selectedNodes = [];
      this.options.selectedEdges = [];
      this.options.highlightID = null
    },
    clickGraph(event) {
      if (this.edit) {
        this.clickGraphInEditMode(event)
      } else {
        this.clickGraphInViewMode(event)
      }
    },
    clickGraphInViewMode(event) {
      let list = []
      if (event.type === "node") {
        if (
            this.options.selectedNodes.length === 1 &&
            this.options.selectedNodes[0] === event.id
        ) {
          this.options.class = ""
          this.options.highlightID = null
        } else {
          list = [event.id]
          this.options.class = "highlight-selected"
        }
        this.options.selectedNodes = list;
      }
    },
    clickNode(event) {
      let list, data, target
      list = [...this.options.selectedNodes]
      data = event.id
      target = "selectedNodes"
      let index = list.indexOf(data)
      if (index !== -1) {
        list.splice(index, 1)
        const edges = [];
        for (let i = 0; i < this.options.selectedEdges.length; i++) {
          let edge = this.options.selectedEdges[i]
          if (edge.a !== event.id && edge.b !== event.id) {
            edges[edges.length] = edge
          }
        }
        if (edges.length !== this.options.selectedEdges.length) {
          this.options.selectedEdges = edges;
        }
      } else {
        list[list.length] = data
      }
      this.options[target] = list;
    },
    clickEdge(event) {
      let list, data, target
      list = [...this.options.selectedEdges]

      data = event.edge
      target = "selectedEdges"
      let index = list.findIndex(element => element.a === data.a && element.b === data.b);
      if (index !== -1) {
        list.splice(index, 1)
      } else {
        list[list.length] = data
      }
      this.options[target] = list;
    },
    clickGraphInEditMode(event) {
      if (event.type === "node") {
        this.clickNode(event)
      } else if (event.type === "edge") {
        this.clickEdge(event)
      }
    },
    findAllDuplicatesById(id){
      if(this.graph[id]){
        return this.graph[id].ids;
      }
    },
    sendDisassemble() {
      const ids = []
      this.options.selectedNodes.forEach(selectedNode => {
        ids.push(...this.findAllDuplicatesById(selectedNode))
      })
      let payload = {
        reference: uuidv4(),
        edges: this.options.selectedEdges,
        dataSetIDs: ids,
        meta: {
          reason: this.editReason,
          user: store.state.user.name
        },
        createConnectionBan: this.editCreateConnectionBan
      }
      api.ir
          .disassemble(payload)
          .then(response => {
            this.$emit("identityChanged", response)
          })
          .catch(error => {
            // TODO: in the future we should resend the event until it succeeds - otherwise some identities might stay locked
            console.error(error)
          })
    }
  }
}
</script>

<style lang="scss">
.identity-graph-container {
  display: flex;

  .left {
    width: 686px;
    margin-right: 10px;
  }

  .right {
    width: 100%;
  }

  .attribs {
    display: flex;
  }

  .attribs > div {
    width: 200px;
    padding: 10px;
    margin: 10px 10px 10px 0;
    border: solid 1px #c8ced3;
  }

  .attribs > div:last-child {
    margin-right: 0;
  }

  .attribs > div div {
    cursor: move;
  }

  .attribs > div div:hover {
    background: #f0f3f5;
  }

  div + button {
    margin-top: 20px;
  }

  .edit-meta {
    h4 {
      margin: 20px 0 10px;
    }

    .required label::after {
      content: " *";
      color: red;
    }

    #edit-reason {
      width: 100%;
      box-sizing: border-box;
    }

    #edit-connection-ban {
      position: relative;
      top: 2px;
    }
  }
}
</style>
