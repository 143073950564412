<template>
  <div class="connection-bans">
    <div v-if="data.connectionBans.length === 0">
      <p>Für diese Identität bestehen keine Verbindungsverbote.</p>
    </div>
    <div v-if="data.connectionBans.length !== 0">
      <table>
        <thead>
        <tr>
          <th></th>
          <th>Verbot auf Identität</th>
          <th>Änderungsgrund</th>
          <th>Benutzer</th>
          <th>Zeitpunkt</th>
        </tr>
        </thead>
        <tr v-for="ban in data.connectionBans" :key="ban.other">
          <td>
            <input type="checkbox" v-model="selectedBans[ban.other]"/>
          </td>
          <td>
            <identity-preview :id="ban.other" @click="$emit('clickedOther', $event)"></identity-preview>
          </td>
          <td>{{ ban.reason }}</td>
          <td>{{ ban.user }}</td>
          <td>{{ new Date(ban.time).toLocaleString() }}</td>
        </tr>
      </table>
      <button @click="sendRemoveConnectionBan" :disabled="others.length === 0">Ausgewählte Löschen</button>
    </div>
  </div>
</template>

<script>
import IdentityPreview from "./IdentityPreview"
import api from "../../api"
import store from "../../store"

import { v4 as uuidv4 } from 'uuid';

export default {
  name: "connection-bans",
  props: ["data"],
  components: {
    IdentityPreview
  },

  data() {
    return {
      selectedBans: {}
    }
  },
  computed: {
    others() {
      let o = []
      for (let k in this.selectedBans) {
        if (this.selectedBans[k]) {
          o[o.length] = k
        }
      }
      return o
    }
  },
  methods: {
    sendRemoveConnectionBan() {
      let payload = {
        reference: uuidv4(),
        identityID: this.data.id,
        others: this.others,
        meta: {
          reason: "(Benutzer hat im Frontend auf Löschen geklickt)",
          user: store.state.user.name
        }
      }
      api.ir
          .removeConnectionBan(payload)
          .then(() => {
            this.$emit("identityChanged", {
              deletedEdges: 0,
              deletedDataSets: 0,
              identityIDs: [this.data.id]
            })
          })
          .catch(error => {
            console.error(error)
          })
    }
  }
}
</script>

<style lang="scss">
.connection-bans {
  th,
  td {
    padding: 0 5px;
    text-align: left;
  }

  th:first-child,
  td:first-child {
    padding-left: 0;
  }

  button {
    margin: 5px 0;
  }
}
</style>
