<template>
  <div class="identity-graph">
    <svg :height="height" :width="width" :class="options.class">
      <line v-for="(link,index) in links" v-bind:key="index"
            :class="{active: isActiveLink(link.source.id, link.target.id), duplicate: link.rule === 'DUP'}"
            :x1="coords[link.source.index].x || 200" :y1="coords[link.source.index].y || 200"
            :x2="coords[link.target.index].x || 200" :y2="coords[link.target.index].y || 200"
            @click.stop="clickLink(link)"></line>
      <template v-if="this.options.showLabel">
        <text v-for="(node, i) in nodes" v-bind:key="i" :class="{farright: (coords[i].x || 200) &gt; 400}"
              :x="coords[i].x || 200" :y="coords[i].y || 200">{{ labelFor(node.id) }}
        </text>
      </template>
      <circle v-for="(node, i) in nodes" v-bind:key="i"
              :class="{active: options.selectedNodes &amp;&amp; options.selectedNodes.includes(node.id)}"
              :cx="coords[i].x || 200" :cy="coords[i].y || 200" r="7" @click.stop="clickNode(node.id)"></circle>
      <circle class="highlight" v-if="options.highlightID" :cx="coords[nodeIdx[options.highlightID]].x || 200"
              :cy="coords[nodeIdx[options.highlightID]].y || 200" r="10"></circle>
      <line class="highlight" v-if="options.highlightEdge" :x1="coords[nodeIdx[options.highlightEdge.a]].x || 200"
            :y1="coords[nodeIdx[options.highlightEdge.a]].y || 200"
            :x2="coords[nodeIdx[options.highlightEdge.b]].x || 200"
            :y2="coords[nodeIdx[options.highlightEdge.b]].y || 200"></line>
    </svg>
  </div>
</template>

<script>
import * as d3 from "d3"

export default {
  name: "identity-graph",
  props: ["graph", "width", "height", "labels", "options"],
  data() {
    return {
      padding: 20,
      nodes: null,
      links: null,
      nodeIdx: null,
      simulation: null
    }
  },
  methods: {
    clickNode(id) {
      this.$emit("click", {
        type: "node",
        id: id
      })
    },
    clickLink(link) {
      this.$emit("click", {
        type: "edge",
        edge: this.edgeOf(link.source.id, link.target.id)
      })
    },
    isActiveLink(a, b) {
      if (
          this.options.selectedNodes.includes(a) ||
          this.options.selectedNodes.includes(b)
      ) {
        return true
      }
      let edge = this.edgeOf(a, b)
      for (let i = 0; i < this.options.selectedEdges.length; i++) {
        let other = this.options.selectedEdges[i]
        if (edge.a === other.a && edge.b === other.b) {
          return true
        }
      }
      return false
    },
    edgeOf(a, b) {
      if (b < a) {
        return { a: b, b: a }
      }
      return { a: a, b: b }
    },
    labelFor(id) {
      const attributes = this.graph[id].attributes
      return this.valueFor(attributes, this.labels)
    },
    valueFor(attributes, names) {
      if (!names) {
        return ""
      }
      let value = []
      for (let i in names) {
        value[i] = attributes[names[i]]
      }
      return value.join(", ")
    }
  },
  computed: {
    bounds() {
      return {
        minX: Math.min(...this.nodes.map(n => n.x)),
        maxX: Math.max(...this.nodes.map(n => n.x)),
        minY: Math.min(...this.nodes.map(n => n.y)),
        maxY: Math.max(...this.nodes.map(n => n.y))
      }
    },
    coords() {
      return this.nodes.map(node => {
        return {
          x:
              this.padding +
              ((node.x - this.bounds.minX) * (this.width - 2 * this.padding)) /
              (this.bounds.maxX - this.bounds.minX),
          y:
              this.padding +
              ((node.y - this.bounds.minY) * (this.height - 2 * this.padding)) /
              (this.bounds.maxY - this.bounds.minY)
        }
      })
    }
  },
  created() {
    let nodes = []
    let links = []
    let nodeIdx = {}

    for (let id in this.graph) {
      let idx = nodes.length
      nodeIdx[id] = idx
      nodes[nodes.length] = { index: idx, id: id }
    }

    for (let id in this.graph) {
      let out = this.graph[id].outgoing
      for (let j in out) {
        links[links.length] = {
          source: nodeIdx[id],
          target: nodeIdx[out[j].id],
          rule: out[j].rule
        }
      }
    }

    this.nodes = nodes;
    this.links = links;
    this.nodeIdx = nodeIdx;


    this.simulation = d3
        .forceSimulation(nodes)
        .force("charge", d3.forceManyBody().strength(-100))
        .force(
            "link",
            d3
                .forceLink(links)
                .distance(2)
                .strength(1)
                .iterations(50)
        )
        .force("x", d3.forceX())
        .force("y", d3.forceY())
  }
}
</script>

<style lang="scss">
.identity-graph {
  svg {
    background: #20a8d8;
    border: solid 2px #000;
  }

  line {
    stroke: rgba(255, 255, 255, 0.3);
    stroke-width: 2;
    pointer-events: none;
  }
  line.duplicate {
    stroke-dasharray: 10 10;
    pointer-events: none !important;
    stroke-width: 2 !important;
    cursor: default !important;
    stroke: rgba(255, 255, 255, 0.3) !important;
  }
  .highlight-selected {
    line {
      stroke: rgba(255, 255, 255, 0.05);
    }
    line.active {
      stroke: #fff;
    }
    line.duplicate {
      stroke: rgba(255, 255, 255, 0.05) !important;
    }
    line.duplicate.active {
      stroke: #fff !important;
    }
  }

  text {
    dominant-baseline: middle;
    fill: #fff;
    transform: translate(10px, 1px);
  }

  text.farright {
    transform: translate(-10px, 1px);
    text-anchor: end;
  }

  circle {
    fill: #fff;
    cursor: pointer;
  }
  circle:hover,
  circle.active {
    r: 10;
  }
  circle.highlight {
    pointer-events: none;
    fill: none;
    stroke: #fff;
    stroke-width: 2;
  }

  .edit {
    line {
      pointer-events: all;
      stroke: rgba(255, 255, 255, 0.3);
    }
    line:hover {
      stroke-width: 6px;
      cursor: pointer;
    }
    circle.active {
      stroke: #f59c00;
      stroke-width: 4px;
      r: 8;
    }
    circle.active:hover {
      r: 10;
    }
    line.active {
      stroke: #f59c00;
    }
    line.highlight {
      stroke-width: 4;
      stroke: #fff;
    }
  }
}
</style>
