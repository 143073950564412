<template>
  <div class="associations">
    <div v-if="data.associations.length === 0">
      <p>Für diese Identität besteht kein Identitätsverbund.</p>
    </div>
    <div v-if="data.associations.length !== 0">
      <table>
        <thead>
        <tr>
          <th>Verbindung mit Identität</th>
          <th>Verbindungsgrund</th>
          <th>Zeitpunkt</th>
        </tr>
        </thead>
        <tr v-for="association in data.associations" :key="association.other">
          <td>
            <identity-preview :id="association.other" @click="$emit('clickedOther', $event)"></identity-preview>
          </td>
          <td>{{ association.reason}}</td>
          <td>{{ new Date(association.time).toLocaleString() }}</td>
        </tr>
      </table>
    </div>
  </div>
</template>

<script>
import IdentityPreview from "./IdentityPreview"

export default {
  name: "associations",
  props: ["data"],
  components: {
    IdentityPreview
  }
}
</script>

<style lang="scss">
.associations {
  th,
  td {
    padding: 0 5px;
    text-align: left;
  }

  th:first-child,
  td:first-child {
    padding-left: 0;
  }

  button {
    margin: 5px 0;
  }
}
</style>
